<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="$t('edit_plan')">
        <template v-slot:actions>
          <li>
            <a
              v-if="!attaching"
              @click="attach"
              href="javascript:void(0)"
              class="btn btn-success btn-floating"
            >
              <i
                class="fas fa-save text-white font-size-22 v-align-text-bottom"
              ></i>
            </a>
            <a
              v-else
              href="javascript:void(0)"
              class="btn btn-success btn-floating"
              disabled
            >
              <span class="qt-loader qt-loader-mini qt-loader-right"> </span>
            </a>
          </li>
        </template>
      </PageHeader>
      <div class="page-content container-fluid">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col-4">
              <div class="card">
                <div class="card-header">
                  {{ $t('generic-str.menu.general') }}
                </div>
                <div class="card-body">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('plan') }}
                    </label>
                    <div class="col-sm-10">
                      <select class="form-control" v-model="form.plan_id">
                        <option
                          v-for="plan in plans"
                          :key="plan.id"
                          :value="plan.id"
                          @click="changeService(plan.service)"
                        >
                          {{ plan.name }} ({{ plan.service }})
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="service == 'omni'" class="col-8">
              <div class="card">
                <!-- <div class="card-header">Omni Business</div> -->
                <div class="card-header">Chat</div>
                <div class="card-body">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.fee"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('message_price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.message"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('agent_price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.agent"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('storage') }}
                    </label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.details.storage"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >GB</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('storage_price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.storage"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('message', 2) }}
                    </label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.details.messages"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >Mensagens</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="service == 'whatsapp'" class="col-8">
              <div class="card">
                <div class="card-header">Whatsapp API</div>
                <div class="card-body">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.fee"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('message_price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.message"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('template_price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.template"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="service == 'rcs'" class="col-8">
              <div class="card">
                <div class="card-header">Google RCS</div>
                <div class="card-body">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.fee"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('message', 2) }}
                    </label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.details.messages"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >Mensagens</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('limit', 2) }}
                    </label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.details.limit"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >Mensagens</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('message_price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.message"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="service == 'sms'" class="col-8">
              <div class="card">
                <div class="card-header">SMS</div>
                <div class="card-body">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.fee"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('message', 2) }}
                    </label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.details.messages"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >Mensagens</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('limit', 2) }}
                    </label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.details.limit"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >Mensagens</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('message_price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.message"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="service == 'email'" class="col-8">
              <div class="card">
                <div class="card-header">E-Mail</div>
                <div class="card-body">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.fee"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('message', 2) }}
                    </label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.details.messages"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >Mensagens</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('limit', 2) }}
                    </label>
                    <div class="col-sm-10">
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="form.details.limit"
                          required
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"
                            >Mensagens</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">
                      {{ $tc('message_price') }}
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.pricing.message"
                        v-money="money"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
    <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
    <div v-else class="static qt-block-ui" style="padding: 120px" />
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import CustomerService from '@/services/customer.service';
import { mask } from 'vue-the-mask';

export default {
  name: 'EditPlan',
  components: {
    PageHeader,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: true,
      },
      plans: [],
      services: {
        // omni: 'Omni Business',
        omni: 'Chat',
        whatsapp: 'Whatsapp API',
        rcs: 'Google RCS',
        sms: 'SMS',
        email: 'E-Mail',
      },
      type: '',
      customer: {},
      service: '',
      form: {
        plan: {},
        pricing: {},
        details: {},
      },
      smsPrices: {
        claro: 0.1,
        vivo: 0.1,
        tim: 0.1,
        oi: 0.1,
        other: 0.1,
      },
      attaching: false,
      pages: 1,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store
        .dispatch('fetchCustomerPlan', {
          accountId: this.$route.params.planId,
          planId: this.$route.params.planId,
        })
        .then((response) => {
          this.fetched = true;
          this.plans = response.data.data;
        });
    },
    changeService(service) {
      this.service = service;
    },
    changeProduct(plan) {
      this.type = plan.type;
    },
    attach() {
      this.attaching = true;
      this.$store
        .dispatch('attachPlan', {
          customerId: this.$route.params.id,
          input: this.form,
        })
        .then((response) => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('adm.customer.assign.associated-plan'),
            type: 'success',
          });
          this.$router.push('../');
        })
        .catch((error) => {
          console.log(error.name);
          console.log(error.toString());
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
              error.code
            }`,
            content: error.message,
            type: 'danger',
          });
        })
        .finally(() => {
          this.attaching = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}
</style>
